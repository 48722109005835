/* eslint-disable @wordpress/dependency-group */
import inView from 'in-view';
import counterUp from 'counterup2';

const facts = Array.from(document.querySelectorAll('.facts'));

const setHeight = () => {
	const fact = Array.from(document.querySelectorAll('.fact'));
	fact.forEach((item) => {
		const itemWidth = item.offsetWidth;
		item.style.minHeight = `${itemWidth}px`;
	});
};

setHeight();

window.addEventListener('resize', setHeight);
window.addEventListener('orientationchange', setHeight);

facts.forEach((fact) => {
	const counter = fact.querySelectorAll('.fact-number span');
	inView('.facts')
		.once('enter', () => {
			if (counter.length) {
				counter.forEach((item) => {
					counterUp(item, {
						duration: 1000,
						delay: 20,
					});
				});
			}
		})
		.on('enter', (el) => {
			el.classList.add('in-view');
		})
		.on('exit', (el) => {
			el.classList.remove('in-view');
		});
});
